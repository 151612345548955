import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '@solid-ui-components/Modal';
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent';
import './Plans.scss';
import OK from './ok.svg';
import Not from './error.svg';
import { Box } from 'theme-ui';
import ContentText from '@solid-ui-components/ContentText';
import { FaAngleDoubleDown } from 'react-icons/fa';

const text = {
    text: 'Planos e Funcionalidades',
    variant: 'h2',
};

const NewPricing = () => {
    const { setActiveModal } = useContext(ModalContext);

    return (
        <section id="plans">
            <div className="plans-content">
                <div className="title">
                    <Box sx={{ textAlign: `center` }}>
                        <ContentText content={text} />
                    </Box>
                </div>
                <div className="information">
                    <table>
                        <thead>
                            <tr style={{ background: 'none' }}>
                                <th style={{ border: 'none' }}></th>
                                <th className="types">
                                    <p className="title-basic">Essencial</p>
                                    <span className="shadow"></span>
                                </th>
                                <th className="types">
                                    <p className="title-basic">Avançado</p>
                                    <span className="shadow"></span>
                                </th>
                                <th className="types">
                                    <p className="title-basic">Profissional</p>
                                    <span className="shadow"></span>
                                </th>
                                <th className="types">
                                    <p className="title-basic">Corporativo</p>
                                    <span className="shadow"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="5" className="resources">
                                    Acervo
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Pesquisa de termos na íntegra</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Jurisprudência de alta relevância (súmulas, repetitivos, IACs, OJs etc)</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Fontes administrativas (CARF, TIT-SP, TCU, CNJ)</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Acesso a dashboard de Jurimetria</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Filtros de pesquisa</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <div>
                                        <div>✓ Tribunais</div>
                                        <div>✓ Datas</div>
                                        <div>✓ Áreas do Direito</div>
                                        <div>✓ Tipo de Julgado</div>
                                    </div>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <div>
                                        <div>✓ Tribunais</div>
                                        <div>✓ Datas</div>
                                        <div>✓ Áreas do Direito</div>
                                        <div>✓ Tipo de Julgado</div>
                                        <div>
                                            <strong>+ Classes da Ação</strong>
                                        </div>
                                        <div>
                                            <strong>+ Câmaras</strong>
                                        </div>
                                        <div>
                                            <strong>+ Seções</strong>
                                        </div>
                                        <div>
                                            <strong>+ Turmas</strong>
                                        </div>
                                        <div>
                                            <strong>+ Nome do juiz</strong>
                                        </div>
                                        <div>
                                            <strong>+ Estados de Origem</strong>
                                        </div>
                                        <div>
                                            <strong>+ Comarca de Origem</strong>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <div>
                                        <div>✓ Tribunais</div>
                                        <div>✓ Datas</div>
                                        <div>✓ Áreas do Direito</div>
                                        <div>✓ Tipo de Julgado</div>
                                        <div>✓ Classes da Ação</div>
                                        <div>✓ Câmaras</div>
                                        <div>✓ Seções</div>
                                        <div>✓ Turmas</div>
                                        <div>✓ Nome do juiz</div>
                                        <div>✓ Estados de Origem</div>
                                        <div>✓ Comarca de Origem</div>
                                        <div>
                                            <strong>+ Assuntos do CNJ</strong>
                                        </div>
                                        <div>
                                            <strong>+ Conhecimento</strong>
                                        </div>
                                        <div>
                                            <strong>+ Provimento</strong>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <div>
                                        <div>✓ Tribunais</div>
                                        <div>✓ Datas</div>
                                        <div>✓ Áreas do Direito</div>
                                        <div>✓ Tipo de Julgado</div>
                                        <div>✓ Classes da Ação</div>
                                        <div>✓ Câmaras</div>
                                        <div>✓ Seções</div>
                                        <div>✓ Turmas</div>
                                        <div>✓ Nome do juiz</div>
                                        <div>✓ Estados de Origem</div>
                                        <div>✓ Comarca de Origem</div>
                                        <div>✓ Assuntos do CNJ</div>
                                        <div>✓ Conhecimento</div>
                                        <div>✓ Provimento</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Download da íntegra no formato original (PDF, DOC, DOCX, RTF, etc)</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="green">Até 200</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="green">Até 1.000</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="green">Até 5.000</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Ilimitado</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="resources">
                                    Gestão de Conteúdo
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Formato da visualização dos Favoritos</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Cartão</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">Cartão + lista</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="green">Cartão + Lista + Download</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Cartão + Lista + Download</span>
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="functionality">
                                    Fazer anotações no julgado
                                    <br />
                                    <span className="soon">Em breve</span>
                                </td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr> */}
                            <tr>
                                <td className="functionality">Criar e editar pastas</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Pastas</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">Pastas + Analytics</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="yellow">Pastas + Analytics</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Pastas + Analytics + Exportação</span>
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="functionality">
                                    Compartilhar pastas com externos (clientes)
                                    <br />
                                    <span className="soon">Em breve</span>
                                </td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr> */}
                            {/* <tr>
                                <td className="functionality">
                                    Compartilhar busca salva
                                    <br />
                                    <span className="soon">Em breve</span>
                                </td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr> */}
                            {/* <tr>
                                <td className="functionality">
                                    Criar alertas para buscas salvas
                                    <br />
                                    <span className="soon">Em breve</span>
                                </td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Até 5</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">Até 50</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="green">Ilimitado</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Ilimitado</span>
                                </td>
                            </tr> */}
                            <tr>
                                <td className="functionality">Consultar histórico de pesquisas realizadas</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Individual</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">Individual + Equipe</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="yellow">Individual + Equipe</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Individual + Equipe + Escritório</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Consultar histórico de julgados visitados</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Individual</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">Individual + Equipe</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="yellow">Individual + Equipe</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Individual + Equipe + Escritório</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Exportação de resultados (gráficos e dashboards)</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Marca d'água da JUIT</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">Sem marca d'água</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="green">Marca d'água do escritório</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Marca d'água do escritório</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Espaço para discussão de teses (comentários)</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="resources">
                                    Outros Benefícios
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Identidade visual e domínios personalizados</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="have">
                                        <img src={Not} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="have">
                                        <img src={OK} alt="JUIT Lawtech Solutions" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Treinamento</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Apenas via gravação</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="yellow">1 sessão ao vivo + gravações</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="yellow">2 sessões ao vivo + gravações</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Até 6 sessões ao vivo + gravações</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">SLA</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Até 6h útil (9h às 18h)</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="red">Até 4h útil (9h às 18h)</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="red">Até 2h útil (9h às 18h)</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="red">Até 1h útil (9h às 18h)</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="functionality">Canal de Suporte</td>
                                <td>
                                    <span className="choice">Essencial</span>
                                    <span className="red">Chat E-mail</span>
                                </td>
                                <td>
                                    <span className="choice">Avançado</span>
                                    <span className="red">Chat E-mail</span>
                                </td>
                                <td>
                                    <span className="choice">Profissional</span>
                                    <span className="yellow">Chat E-mail Teams/Slack</span>
                                </td>
                                <td>
                                    <span className="choice">Corporativo</span>
                                    <span className="green">Chat E-mail Teams/Slack Telefone</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default WithDefaultContent(NewPricing);
